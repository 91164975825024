import React from "react";
import { Card, CardBody, CardImg, CardText, CardTitle, Col } from "reactstrap";
import ReactLoading from "react-loading";

const CardComponent = ({ name, title, mainPhoto, slug, description }) => {
  return (
    <Col md="3" className="hover-white" style={{ margin: ".5rem 0" }}>
      <a style={{ textDecoration: "none" }} href={`/${name}/${slug}`}>
        <Card className="border-0">
          {mainPhoto ? (
            <CardImg
              style={{ height: "200px", objectFit: "contain" }}
              top
              width="100%"
              src={mainPhoto}
              alt="Card image cap"
            />
          ) : (
            <ReactLoading
              type={"bars"}
              color={"white"}
              height={667 / 2}
              width={375 / 2}
            />
          )}
          <CardBody className="category-body">
            <CardTitle className="category-title">{title}</CardTitle>
            <CardText dangerouslySetInnerHTML={{__html:description}} className="category-description"/>
          </CardBody>
        </Card>
      </a>
    </Col>
  );
};

export default CardComponent;
